import { AfterViewChecked, Component, ElementRef, EventEmitter, Inject, Input, Output, OnInit, OnChanges } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { DashcamService } from '../../services/dashcam.service';
import {values} from 'lodash';
import {SimpleChanges} from '@angular/core';

const commonMin = new Date(new Date().setFullYear((new Date().getFullYear() - 10)));
const commonMax = new Date(new Date().setDate((new Date().getDate())));

@Component({
  selector: 'app-dashcam-date-range-modal',
  templateUrl: './dashcam-date-range-modal.component.html',
  styleUrls: ['./dashcam-date-range-modal.component.css']
})
export class DashcamDateRangeModalComponent implements AfterViewChecked, OnInit, OnChanges {

  private suscription: Subscription = new Subscription;
  @Output() runEntities = new EventEmitter<any>();
  @Input() prop: string;
  ngOnChanges(changes: SimpleChanges) {
    console.log(changes.prop);
  }

  state = 'inputData';
  events = [];
  videoUrls = {};
  selectedVideo = null;
  getLinksSpinner = {};
  fromDate = new Date(new Date().setDate((new Date().getDate() - 7)));
  toDate = new Date();

  rangeFrom: any = {
    min: commonMin,
    max: commonMax,
  };

  rangeTo: any = {
    min: commonMin,
    max: commonMax,
  };

  datePicker: any = {
    from: this.fromDate,
    to: this.toDate
  };

  valueasd: any = {
    from: this.fromDate,
    to: this.toDate
  };

  loadingEvents = false;

  selectedPath = null;

  videoDownload: string = 'Select One …';

  memoVideos = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DashcamDateRangeModalComponent>,
    public dialog: MatDialog,
    private dashcamService: DashcamService,
  ) {}

  ngAfterViewChecked() {
  }

  ngOnInit() {
    this.getRangeVideos();
  }

  ngOnDestroy() {
    this.suscription.unsubscribe();
  }

  close(): void {
    this.dialogRef.close(false);
  }

  getRangeVideos() {
    let fromTemp = null;
    let toTemp = null;
    fromTemp = (this.datePicker.from._isAMomentObject) ? this.datePicker.from.unix() : Math.round(this.datePicker.from.getTime() / 1000);
    toTemp = (this.datePicker.to._isAMomentObject) ? this.datePicker.to.unix() : Math.round(this.datePicker.to.getTime() / 1000);
    const device = this.data.camDevice;
    const payload = {
      startDateTime: fromTemp,
      endDateTime: toTemp,
    };
    this.loadingEvents = true;
    this.dashcamService.getDashCode(device, payload).subscribe((resp: any) => {
      if (resp && resp.status) {
        resp.data.sort((a, b) => {
          const dateA = new Date(a.utcDateTime);
          const dateB = new Date(b.utcDateTime);
          return dateB.getTime() - dateA.getTime();
        });
        this.events = resp.data;
      }
      this.loadingEvents = false;
    });
  }
  onNoClick(): void  {
    this.dialogRef.close(false);
  }

  downloadSignedUrl(filePath: string): void {
    this.getLinksSpinner = {...this.getLinksSpinner, [filePath]: true};
    this.dashcamService.getSignedUrl(this.data.camDevice.device, filePath).subscribe((resp: any) => {
      resp.data.forEach(element => {
        this.videoUrls = {...this.videoUrls, [filePath]: [...Object.values(element)]};
      });
      this.getLinksSpinner[filePath] = false;
      this.selectedPath = filePath;
      this.selectedVideo = this.videoUrls[filePath][0];
      this.videoDownload = 'Select One …';
    });
  }
  selection(url: string) {
    this.selectedVideo = url;

  }
  checkUrl(url: string) {
    return url.includes('.mp4');
  }
  back() {
    this.selectedVideo = null;
    this.selectedPath = null;
    this.memoVideos = null;
  }
  getVideos(selectedPath: string) {
    if (this.memoVideos) {
      return this.memoVideos;
    }
    return this.memoVideos = this.videoUrls[selectedPath].filter(url => url.includes('.ts'));
  }
  sencVideoDownload(videoDownload: string) {
    if(videoDownload !== "Select One …") {
      this.videoDownload = videoDownload;
      window.open(videoDownload, '_blank').focus();
    }
  }
  getNameVideo(videoDownload: string) {
    return videoDownload.split(/.ts\?/)[0].replace('https://s3.us-east-2.amazonaws.com/video.matrack-dashcam.matrack.com/AlertMedia/MAT/', '').split('/')[1]//.replace('MAT/20241218/', '');
  }

  protected readonly values = values;
}
