import {AfterViewChecked, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import Mpegts from 'mpegts.js';

@Component({
  selector: 'app-videots-thumbnail-player',
  templateUrl: './videots-thumbnail-player.component.html',
  styleUrls: ['./videots-thumbnail-player.component.css']
})
export class VideoTSThumbnailPlayerComponent implements OnInit, OnDestroy {
  @ViewChild('videoPlayer', {static: true}) videoElement!: ElementRef;
  @Input() selectedVideo: string;
  api: Mpegts.Player | null = null;
  private initialized = false;

  mpegtsConfig: Mpegts.Config = {};
  private defaultMpegtsConfig: Mpegts.Config = {
    lazyLoadMaxDuration: 3 * 60,
    enableWorker: true,
    reuseRedirectedURL: true,
  };

  mpegtsLoggingConfig: Partial<Mpegts.LoggingControlConfig> = {};
  private defaultMpegtsLoggingConfig: Mpegts.LoggingControlConfig = {
    forceGlobalTag: false,
    globalTag: 'mpegts-video-element',
    enableAll: false,
    enableDebug: false,
    enableVerbose: false,
    enableInfo: false,
    enableWarn: false,
    enableError: false,
  };

  constructor() {
  }

  ngOnInit() {
    if (Mpegts.getFeatureList().mseLivePlayback) {
      Mpegts.LoggingControl.applyConfig({
        ...this.defaultMpegtsLoggingConfig,
        ...this.mpegtsLoggingConfig,
      });

      this.api = Mpegts.createPlayer(
        {
          type: 'mse',
          isLive: true,
          url: this.selectedVideo,
        },
        {...this.defaultMpegtsConfig, ...this.mpegtsConfig},
      );

      this.api.attachMediaElement(this.videoElement.nativeElement);
      this.api.unload();
      this.api.load();
      //this.videoElement.nativeElement.play();

      this.api.on(Mpegts.Events.ERROR, (event) => {
        console.error(event);
        this.api.unload();
        this.api.load();
        //this.videoElement.nativeElement.play();
      });
    }

  }

  ngOnDestroy() {
    this.api.destroy();
  }
  loadPlayer (selectedVideo: string){

  }
}
