import { Component, Input, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Subscription } from 'rxjs'
import { fEventNameConventions } from '../../helpers/helpers'
import { GeneralService } from '../../services/general.service'
import { RequestService } from '../../services/request.service'
import { GeneralModalComponent } from '../general-modal/general-modal.component'

@Component({
  selector: 'ranking',
  templateUrl: './ranking.component.html',
  styleUrls: ['./ranking.component.css']
})
export class RankingTemplateComponent implements OnInit {
  private suscription: Subscription = new Subscription;
  @Input('score') score: any;
  contexts: any;
  wiState: boolean;
  scoreValues: any;
  generatedScore: any = {};
  scoreWeekFilter: any = {};

  constructor(
    private gs: GeneralService,
    private rs: RequestService,
    public dialog: MatDialog,
  ) {
    this.suscription.add(this.gs.getWiContext.subscribe((data: any[]) => this.contexts = data));
    this.suscription.add(this.gs.getWiData.subscribe((data: any) => {
      if (!data) {
        this.generatedScore = {};
        return;
      }
      this.dataBasedActions(data.dataBasedAction);
    }));

    this.suscription.add(this.gs.getWiState.subscribe(state => this.wiState = state));
    this.suscription.add(this.gs.getScoreWeekFilter.subscribe((scoreWeekFilter: any) => {
      if (!scoreWeekFilter) { return; }
      console.log(scoreWeekFilter)
      this.reqNewScore(scoreWeekFilter);
    }));
  }

  ngOnInit() {
    if ( !(this.score.scoreVariables || []).length ) { return; }
    this.score.scoreVariables = this.score.scoreVariables
      .sort((a, b) => {
        if ( (a.position > b.position) ) { return 1; }
        if ( (b.position > a.position) ) { return -1; }
        return 0;
      });
  }

  ngOnDestroy() {
    this.suscription.unsubscribe();
  }

  reqNewScore(scoreWeekFilter) {
    const addZero = number => number < 10 ? `0${number}` : number;
    this.scoreWeekFilter = {
      from: `${scoreWeekFilter.from.year}-${addZero(scoreWeekFilter.from.week)}`,
      to: `${scoreWeekFilter.to.year}-${addZero(scoreWeekFilter.to.week)}`,
    };
    this.genScore(scoreWeekFilter.prevData.scoreType);
  }

  async genScore(score) {
    const historic = score.type.indexOf('history') > -1;
    if ( historic ) { return this.openHistoric(score); }
    this.gs.setCurrentScoreType(score.type);
    this.gs.logScreenEvents('score_click_driver', 'score_click_driver', 'score_click_driver', 'click');

    console.log(score, score.id, this.scoreWeekFilter)
    this.scoreValues = await this.rs.request({
      method: 'GET',
      endpoint: 'get_score_values',
      scoreId: score.id,
      filter: this.scoreWeekFilter
    });
    const data: any = this.serializeScoreData(this.scoreValues);
    if (!data) { return; }
    data.scoreType = score;
    this.gs.setWindow({
      state: this.wiState,
      extendedState: true,
      type: 'ranking',
      contexts: this.contexts,
      data
    });
    const eventName = fEventNameConventions[ 'score' ][ score.type ];
    this.gs.logScreenEvents(eventName, eventName, eventName, 'click');
  }

  genScoreByVariable(score, scoreVariable) {
    const data: any = this.serializeScoreData(this.scoreValues, scoreVariable);
    if (!data) { return; }
    data.scoreType = score;
    this.gs.setWindow({
      state: this.wiState,
      extendedState: true,
      type: 'ranking',
      contexts: this.contexts,
      data
    });
  }

  openHistoric(score) {
    const eventName = fEventNameConventions['score'][score.type];
    this.gs.logScreenEvents(eventName, eventName, eventName, 'click');
    const types = { security_history: 'security', efficiency_history: 'efficiency' };
    this.dialog.open(GeneralModalComponent, {
      panelClass: 'general-modal',
      width: '80vw',
      data: { type: 'score', scoreType: types[ score.type ] }
    });
  }

  serializeScoreData(res, scoreVariable = null) {
    const oldSv = (this.generatedScore || {}).scoreVariable;
    if ((oldSv || {}).type === (scoreVariable || {}).type) { scoreVariable = null; }
    if (!res) { return; };
    let { drivers, weeksRange } = res;
    weeksRange = setRangeYears(weeksRange);
    if (!drivers || !drivers.length) { return; }
    const data = {
      scoreGlobal: () => {
        return {
          scoreVariable,
          weeksRange,
          drivers: drivers.map(d => {
            const driverScore = (d.score || {});
            return {
              ...{
                name: d.name,
                picture: d.picture,
                scoreInfo: driverScore.scoreInfo,
                scoreVariables: driverScore.scoreVariables,
              }, ...createObjFrom(driverScore,
                ['type', 'score', 'previousScore', 'trafficLight', 'rank',
                  'previousRank', 'tendency']
              )
            };
          }).sort((a: any, b: any) => a.rank - b.rank)
        };
      },
      scoreVariable: () => {
        return {
          scoreVariable,
          weeksRange,
          drivers: drivers.map(d => {
            const driverScore = (d.score || {});
            const obj = (driverScore.scoreVariables || [])
              .find(sv => sv.type === scoreVariable.type) || {};
            obj.name = d.name;
            obj.picture = d.picture;
            obj.scoreInfo = driverScore.scoreInfo;
            return obj;
          }).sort((a: any, b: any) => a.rank - b.rank)
        };
      }
    };
    this.generatedScore = !scoreVariable ? data['scoreGlobal']() : data['scoreVariable']();
    return this.generatedScore;
  }

  closeWindow() {
    this.gs.setWindow({
      state: this.wiState,
      extendedState: false,
      type: 'list',
      contexts: this.contexts,
      data: null
    });
  }

  dataBasedActions(action) {
    if (!this.generatedScore.scoreType) { return; }
    const actions = {
      generalScore: _ => {
        this.genScore(this.generatedScore.scoreType);
      }
    };
    actions[action] && actions[action]();
  }

}

const createObjFrom = (obj, properties) => {
  const newObj = {};
  properties.forEach(k => newObj[k] = obj[k]);
  return newObj;
};

const setRangeYears = weekRange => {
  const fromArr = weekRange.from.date.split(' ');
  const toArr = weekRange.to.date.split(' ');
  weekRange.from.year = fromArr[fromArr.length - 1];
  weekRange.to.year = toArr[toArr.length - 1];
  return weekRange;
};
