import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { DashcamService } from '../../../services/dashcam.service'
import { DevicesService } from '../../../services/devices.service'
import { DriverService } from '../../../services/driver.service'
import { GeneralService } from '../../../services/general.service'
import { HomeService } from '../../../services/home.service'
import { MarkerService } from '../../../services/marker.service'
import { PerformanceService } from '../../../services/performance.service'
import { GeneralModalComponent } from '../../general-modal/general-modal.component'

@Component({
  selector: 'driver-template',
  templateUrl: './driver-template.component.html',
  styleUrls: ['./driver-template.component.css']
})
export class DriverTemplateComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  map: any;
  @Input() driver: any;
  @Input() isFreemium: boolean;

  selected: any;
  contexts: any;
  type: string;
  showingRoute: boolean;
  devices: any;
  drivers: any = [];
  mGroup: any;
  findText: string;
  toolBarButtons: any;
  expanded: boolean;
  @Output() runEntities = new EventEmitter<any>();

  constructor(
    public gs: GeneralService,
    public deviceService: DevicesService,
    public driverService: DriverService,
    public homeService: HomeService, // Unused, consider removing
    public markerService: MarkerService,
    private ps: PerformanceService,
    private dashcamService: DashcamService,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.gs.getShowLiveViewRoute.pipe(takeUntil(this.destroy$)).subscribe(show => this.showingRoute = show);
    this.gs.getWiContext.pipe(takeUntil(this.destroy$)).subscribe(data => this.contexts = data);
    this.gs.getWiType.pipe(takeUntil(this.destroy$)).subscribe(data => this.type = data);
    this.gs.getMap.pipe(takeUntil(this.destroy$)).subscribe(data => this.map = data);
    this.gs.toolbarButtons.pipe(takeUntil(this.destroy$)).subscribe(data => this.toolBarButtons = data);
    // use -> devices.vehicle
    this.deviceService.getCurrentDevices.pipe(takeUntil(this.destroy$)).subscribe(devices => this.devices = devices);
    this.driverService.getCurrentDrivers.pipe(takeUntil(this.destroy$)).subscribe(drivers => {this.drivers = drivers;});
    this.markerService.getMGroup.pipe(takeUntil(this.destroy$)).subscribe(g => this.mGroup = g);
    this.gs.getWiFindText.pipe(takeUntil(this.destroy$)).subscribe(findText => this.findText = findText);

    this.gs.getWiData.pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (!data) { return; }
      this.selected = data.current_driver?.type ? data.current_driver : this.drivers.find(d => d.id === data.current_driver);
      if (data.current_vehicle) {
        this.selected = data.type ? data : this.drivers.find(d => d.id === data);
      }
    });
  } 

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getDashCam(dashcamId: string) {
    this.dialog.open(GeneralModalComponent, {
      panelClass: 'general-modal',
      width: '70vw',
      data: { camDevice: dashcamId, type: 'dashcam' }
    });
  }

  fullName(name: string, lastName: string): string {
    return `${name.split(' ')[0]} ${lastName.split(' ')[0]}`;
  }

  closePanel(): void {
    this.selected = undefined;
    this.gs.setWindow({ state: true, extendedState: false, type: 'list', contexts: this.contexts });
  }

  genTrafficLight(trafficLight: string): any { // Return type should be defined
    const trafficLightObj = this.gs.getDriverTrafficLights().find(t => t.type === trafficLight);
    return trafficLightObj || trafficLightObj; // Removed unnecessary [1] index access
  }


  getNameFirstLetter(name: string, lastName: string): string {
    return `${name?.[0] || ''} ${lastName?.[0] || ''}`; // Simplified using optional chaining and bracket notation
  }


  openTrips(): void {
    const eventName = 'driver_travel_list';
    this.gs.logScreenEvents(eventName, eventName, eventName, 'click');
    const context = this.contexts.filter(c => new RegExp(this.driver.type).test(c.context));
    this.gs.setWindow({ state: true, extendedState: false, type: 'trips', contexts: context, data: this.driver });
  }

  openAdvices() {
    const context = this.contexts.filter(c => new RegExp(this.driver.type).test(c.context));
    this.gs.setWindow({
      state: true, extendedState: true, type: 'list', contexts: context, data: {
        driver: this.driver,
        type: 'driver_advice'
      }
    });
  }

  openExtension() {
    this.gs.setWindow({ state: true, extendedState: true, type: 'list', contexts: this.contexts });
  }

  selectedDriver(driver, panel = null) {
    if ( this.isFreemium && panel ) { panel._toggle(); }
    let vehicle = driver ? driver.current_vehicle : driver;
    if (!vehicle) { return this.gs.addSelectedClass(false); }
    this.gs.setShowLiveViewRoute(false);
    vehicle = Object.assign({}, vehicle, {
      device: this.devices.find(d => d.id === vehicle.device)
    });
    const marker = this.mGroup.findByImei(vehicle.device.imei);
    if (marker) {
      this.gs.goToMarker(marker, vehicle, this.map, this.contexts);
      this.gs.setWindow({ state: true, data: driver, type: this.type, contexts: this.contexts });
    } else { this.gs.addSelectedClass(false); }
  }

  callPhone(panelH, phone) {
    panelH._toggle();
    return window.open(`tel:${phone}`);
  }

  openEditMode(panelH, driver) {
    panelH._toggle();
    this.openGeneralModal(driver, 'edit_driver');
    this.gs.logScreenEvents('driver_click_edit', 'driver_click_edit', 'driver_click_edit', 'click');
  }

  goToRanking() {
    if (!this.driver.scores[0]) { return; }
    const context = 'ranking';
    const rankingButton = this.toolBarButtons.filter(b => b.context === context);
    this.gs.setWindow({ state: true, type: context, contexts: rankingButton, data: { type: 'security' } });
  }

  tendencyIcon(prevScore, currScore) {
    if (currScore === prevScore) { return 'tendency-flat-yellow'; }
    if (currScore > prevScore) { return 'tendency-up'; }
    if (currScore < prevScore) { return 'tendency-down'; }
  }

  openGeneralModal(driver, type) {
    this.ps.setDriverIdFilter(driver.id);
    const dialogRef = this.dialog.open(GeneralModalComponent, {
      panelClass: 'general-modal',
      width: '80vw',
      data: { ...driver, type }
    });

    dialogRef.afterClosed().subscribe(() => {
      this.runEntities.emit(true);
    });
  }

  ignoreIfOpen(panel) {
    if (!this.selected || !this.selected._id) { return; }
    return panel._toggle();
  }

}
