import { Injectable } from '@angular/core'
import { environment as env } from '../../environments/environment'
import { endpoints } from '../helpers/endpoint.helpers'

const reqError = {
  method: { error: { message: 'INTRODUCE_METHOD' }},
  endpoint: { error: { message: 'INTRODUCE_ENDPOINT' }},
  body: { error: { message: 'INTRODUCE_BODY' }}
};

const requestFunction = async opts => {
  try {
    // These endpoints use appToken intead of session token
    const appToken = _ => ['login', 'validate_restore_code', 'get_magic_link', 'test_magiclink_token', 'confirm_account'].some(t => t === _)

    const required = Object.keys(reqError).find( k => !opts[k] ? opts[k] : null)

    if ( required ) { return required }
    if ( (opts.method === 'POST' || opts.method === 'PUT' || opts.method === 'PATCH') && !opts.body ) {
      return reqError['body']
    }

    const token = appToken(opts.endpoint) ? env.API_TOKEN : localStorage.getItem('id')

    opts.headers = new Headers({
      'Content-Type': 'application/json',
      'authorization': `Bearer ${token}`
    })

    if (opts.body) {
      opts.body = JSON.stringify(opts.body)
    }

    let searchParams = ''
    if (opts.params) {
      searchParams = '?' + new URLSearchParams(opts.params).toString()
    }

    const res = await fetch(endpoints[opts.endpoint](opts) + searchParams, opts)

    if ( !res.ok ) {
      return { error: true }
    }
    return opts.fullRes ? res : res.json()

  } catch (error) {}
}

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  constructor() { }

  async request(opts) {
    return requestFunction(opts)
  }
}

export {
  requestFunction as request
}

